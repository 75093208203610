import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower, split, find, get, map, includes } from 'lodash';

import { authenticationService, principalService, kvaasService } from 'common/services';
import { kvaasResources, initialPageOptions, parseError, getMail, logger, logLoginAttempt } from 'common/utilities';
import { withLoader } from 'common/components/loader';
import { Notification } from 'common/components/notifications';
import { withCancelable } from 'common/components/cancelable';
import { PasswordInput } from 'common/components/password-input';
import PrivacyPolicyFooter from 'common/components/footer/PrivacyPolicyFooter';
import ModalComponent from 'common/components/modal/modal';

class LoginComponent extends Component {
	constructor(props) {
		super(props);
		this.usernamePersistenceKey = 'prsstnm';
		const persistedUserName = this.getPersistedUsername();
		this.state = {
			username: get(props, 'location.state.username', persistedUserName),
			password: '',
			errorMessage: get(props, 'location.state.errorMessage', ''),
			inactivityWarning: get(props, 'location.state.inactivity', false),
			rememberUser: persistedUserName.length > 1,
			showModal: false,
			modalData: null,
		};
	}

	componentWillMount() {
		if (principalService.get()) {
			return this.redirect();
		}
	}

	setErrorMessage = errorMessage => {
		this.setState({ errorMessage });
	};
	handleAuthenticationError = (err, username) => {
		this.setState({ pendingRequest: false });
		if (get(err, 'isCanceled')) {
			return;
		}
		logger.logError({
			message: 'Authentication error occurred.',
			errorDetails: err,
			username,
		});
		const { stack } = parseError(err);
		this.props.showLoader(false);
		if (includes([401, 403], get(err, 'ex.response.status'))) {
			this.setState({
				errorMessage:
					'You are not authorized to access the page. Contact customer support: gatewaysupport@solapayments.com',
			});
		} else {
			this.setState({
				errorMessage: getMail(stack, {}),
			});
		}
	};

	handleSigninError = (err, username) => {
		const { history } = this.props;
		let message;
		switch (err.code) {
			case 'InvalidParameterException': {
				if (
					toLower(err.message) === 'custom auth lambda trigger is not configured for the user pool.' ||
					toLower(err.message === 'custom_auth is not enabled for the client.')
				) {
					message = 'Please enter your password.';
				} else {
					message = err.message;
				}
				break;
			}
			case 'UserNotConfirmedException': {
				history.push({
					pathname: '/confirm-registration',
					state: { username: username },
				});
				break;
			}
			case 'PasswordResetRequiredException': {
				this.notification.addNotification({
					infoMessage: true,
					message:
						'Please change your password. Our system indicates your current password is too generic, was flagged as compromised in the past, or has expired.',
					success: false,
					forceCloseHandler: true,
					onClose: () => {
						history.push({
							pathname: '/confirm-new-password',
							state: { username: username },
						});
					},
				});
				break;
			}
			case 'NotAuthorizedException':
			case 'UserNotFoundException': {
				if (includes(toLower(err.message), 'security')) {
					message =
						'We cannot continue with your login at this time. Please check your email to confirm this login and then try again.';
				} else {
					message = 'The username/password provided is incorrect.';
				}
				break;
			}
			case 'MFAMethodNotFoundException': {
				message = 'Multi factor authentication setup is incomplete, please contact customer service for assistance';
				break;
			}
			default: {
				if (!this.state.username && this.state.password) {
					message = 'Please enter your email.';
				} else if (!this.state.password) {
					message = 'Please enter your email and password.';
				} else {
					message = 'Something went wrong. Please try again.';
				}
				break;
			}
		}
		this.setState({ errorMessage: message, pendingRequest: false });
	};

	handleChange = async event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleSubmit = async event => {
		if (event) {
			event.preventDefault();
		}
		const { pendingRequest } = this.state;
		const { history, isLoading } = this.props;
		if (isLoading || pendingRequest) {
			return;
		}
		const { password } = this.state;
		let { username, rememberUser } = this.state;
		username = trim(toLower(username));

		this.setState({ errorMessage: '', showModal: false, modalData: null, pendingRequest: true });
		this.props.showLoader(true);
		logLoginAttempt(this.state.username);

		let user;
		try {
			user = await Auth.signIn(username, password);
			// If we do not do this, we cannot pass this property through the history state
			if (get(user, 'pool.wrapRefreshSessionCallback')) {
				delete user.pool.wrapRefreshSessionCallback;
			}
		} catch (err) {
			logger.logError({
				message: 'Sign in error occurred.',
				errorDetails: err,
				username,
			});
			this.handleSigninError(err, username);
			this.props.showLoader(false);
			return;
		}
		// Force change password
		if (get(user, 'challengeName') === 'NEW_PASSWORD_REQUIRED') {
			this.props.showLoader(false);
			history.push({
				pathname: '/change-password',
				state: { username: username },
			});
			return;
		} else if (includes(['SOFTWARE_TOKEN_MFA', 'SMS_MFA'], get(user, 'challengeName'))) {
			this.props.showLoader(false);
			history.push({
				pathname: `/confirm-mfa`,
				search: window.location.search,
				state: {
					user,
					username,
					rememberUser,
					password,
					usernamePersistenceKey: this.usernamePersistenceKey,
					challengeName: user.challengeName,
					challengeDestination:
						user.challengeParam.CODE_DELIVERY_DESTINATION || user.challengeParam.FRIENDLY_DEVICE_NAME,
				},
			});
			return;
		} else {
			const token = get(user, 'signInUserSession.idToken.jwtToken', false);
			if (token) {
				try {
					const auth = await this.props.makePendingRequest(authenticationService.login(token, this.state.username));
					this.props.showLoader(false);
					this.setState({ pendingRequest: true });
					if (auth.success) {
						rememberUser
							? localStorage.setItem(this.usernamePersistenceKey, username)
							: localStorage.removeItem(this.usernamePersistenceKey);
						await this.redirect(auth.redirectToTerms, auth.isDefaultKey, auth.allKeys, token, username);
					} else {
						Auth.signOut();
						this.notification.addNotification({
							message: auth.message,
							ref: auth.ref,
							success: auth.success,
						});
					}
				} catch (err) {
					this.handleAuthenticationError(err, username);
				}
			} else {
				this.setState({
					errorMessage:
						'You are not authorized to access the page. Contact customer support: gatewaysupport@solapayments.com',
					pendingRequest: false,
				});
				this.props.showLoader(false);
			}
		}
	};

	async redirect(redirectToTerms, isDefaultKey, allKeys, token, username) {
		if (isDefaultKey === false) {
			this.setState({
				showModal: true,
				modalData: {
					allKeys,
					token,
					username,
					redirectToTerms,
				},
			});
			return;
		}

		const { history, location } = this.props;
		let redirectUrl = redirectToTerms ? '/terms-and-conditions' : '/';
		let search = '';
		let additionalState;

		try {
			if (!redirectToTerms) {
				const [userSettings] = await kvaasService.get(kvaasResources.userSettings);
				const initialPage = get(userSettings, 'data.initialPage', false);
				const isViewOnly = get(principalService.get(), 'isViewOnly', false);
				if (initialPage) {
					const route = find(initialPageOptions, { key: initialPage });
					if (route && !(isViewOnly && includes(['newTransactions', 'newCustomers'], route.key))) {
						redirectUrl = route.path;
						additionalState = route.state;
					}
				}
			}
		} catch (e) {
			logger.logError({
				message: 'T&C Redirect error.',
				errorDetails: e,
				username,
			});
		}

		// hide loader before redirect
		this.props.showLoader(false);
		if (get(location, 'state.returnUrl') && !redirectToTerms) {
			[redirectUrl, search] = split(location.state.returnUrl, '?');
		}

		history.push({
			pathname: redirectUrl,
			search,
			state: redirectToTerms ? { ...location.state } : {},
			...(additionalState || {}),
		});
	}

	toggleRememberMe = event => {
		this.setState({ rememberUser: event.target.checked });
	};

	redirectToRegister = () => {
		const { history } = this.props;
		history.push('/register');
	};

	redirectToForgotPassword = () => {
		const { history } = this.props;
		history.push('/forgot-password');
	};

	getPersistedUsername = () => {
		let persistedUserName = localStorage.getItem(this.usernamePersistenceKey);
		return persistedUserName ? (persistedUserName.length > 1 ? persistedUserName : '') : '';
	};

	setCurrentAsDefault = async () => {
		const { isLoading, showLoader } = this.props;
		if (isLoading) {
			return;
		}
		showLoader(true);
		const {
			modalData: { allKeys, token, username, redirectToTerms },
		} = this.state;
		try {
			const { id } = principalService.get();
			await Promise.all([
				...map(allKeys, user =>
					authenticationService.save(
						{
							...user,
							sortorder: user.key === id ? 1 : user.sortorder + 2,
						},
						username,
						token
					)
				),
			]);
			await authenticationService.refreshKeys(token, username);
		} catch (e) {
			logger.logError({
				message: 'Set current as default error.',
				errorDetails: e,
				username,
			});
		}
		showLoader(false);
		return this.redirect(redirectToTerms, true);
	};

	render() {
		const { username, password, errorMessage, inactivityWarning, rememberUser, showModal } = this.state;

		return (
			<React.Fragment>
				<form className="auth__form" onSubmit={this.handleSubmit}>
					<h2 className="auth__form__title">Sign in to Sola!</h2>
					{inactivityWarning ? (
						<p className="type--color--error spc--bottom--sml">Your session has expired. Please log in again.</p>
					) : null}
					<div className="form__group">
						<div className="form__group__header">
							<span className="form__group__label">Email</span>
						</div>
						<input
							name="username"
							type="email"
							className="input input--med"
							placeholder="user@email.com"
							value={username}
							onChange={this.handleChange}
							autoFocus
							tabIndex="0"
							inputMode="email"
						/>
					</div>
					<div className="form__group">
						<div className="form__group__header">
							<span className="form__group__label">Password</span>
						</div>
						<PasswordInput
							value={password}
							onChange={this.handleChange}
							tabIndex="0"
							setErrorMessage={this.setErrorMessage}
						/>
						{errorMessage ? <div className="type--color--error spc--top--tny">{errorMessage}</div> : null}
					</div>
					<div className="flex--tertiary flex--gap--sml--alt spc--bottom--xlrg">
						<div>
							<input
								type="checkbox"
								id="rememberuser"
								checked={rememberUser}
								onChange={this.toggleRememberMe}
								className="input--check"
								disabled={this.props.isLoading}
								tabIndex="0"
							></input>
							<label htmlFor="rememberuser">Remember me</label>
						</div>
						<a
							href="javascript:void(0)"
							onClick={this.redirectToForgotPassword}
							className="btn btn--link btn--link--underline type--sml"
						>
							Forgot your password?
						</a>
					</div>

					<button
						type="submit"
						className="btn btn--primary btn--lrg spc--bottom--lrg--alt"
						tabIndex="0"
						disabled={this.props.isLoading}
					>
						Sign in
					</button>
					<div className="auth__form__create-pass">
						<p>Need a login?</p>{' '}
						<button
							type="button"
							onClick={this.redirectToRegister}
							className="btn btn--sml btn--link btn--link--underline"
						>
							Create your password
						</button>
					</div>
				</form>
				<PrivacyPolicyFooter />

				<ModalComponent
					isOpen={showModal}
					onClose={() => {}}
					hideHeaderCloseButton={true}
					shouldCloseOnOverlayClick={false}
				>
					<div>
						<div className="modal__body type--med">Oops... We were unable to access your default account.</div>
						<div className="modal__footer">
							<button className="btn btn--med btn--secondary" type="button" onClick={this.handleSubmit}>
								Try again
							</button>
							<button
								className="btn btn--med btn--primary spc--left--sml"
								type="button"
								onClick={this.setCurrentAsDefault}
							>
								Set "{principalService.get().companyName}" as default
							</button>
						</div>
					</div>
				</ModalComponent>
				<Notification ref={el => (this.notification = el)} />
			</React.Fragment>
		);
	}
}

LoginComponent.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	showLoader: PropTypes.func,
	makePendingRequest: PropTypes.func,
	isLoading: PropTypes.bool,
};

export default withCancelable(withLoader(LoginComponent));
